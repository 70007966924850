import Icon from 'components/Icon'
import InvisibleButton from 'components/InvisibleButton'
import { PurchaseTypeOption } from 'components/PurchaseTypeSelector'
import QuantitySelector from 'components/QuantitySelector'
import SubscribeLearnMore from 'components/cart/SubscribeLearnMore'
import useOnClickOutside from 'hooks/useOnClickOutside'
import useShopifyData, { extractNumericFromGID } from 'hooks/useShopifyData'
import { fireAnalyticsEvent, fireElevarAnalyticsEventGeneric } from 'lib/analytics'
import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'
import theme from 'styles/theme'

const getSellingPlan = (variantId, sellingPlans) => {
  const sellingPlanGroup = sellingPlans.find(planGroup =>
    planGroup.external_variant_ids.includes(variantId)
  )
  if (sellingPlanGroup) {
    return sellingPlanGroup.selling_plans.find(plan => plan.order_interval_frequency === 30)
  }
  return null
}

const getSellingPlanId = plan => {
  if (plan) {
    return `gid://shopify/SellingPlan/${plan.selling_plan_id}`
  }
  return null
}

const LineItem = ({
  cart,
  image,
  linePrice,
  attributes,
  price,
  quantity,
  title,
  lineId,
  productId,
  variantId,
  variantTitle,
  variantSku,
  sellingPlanAllocation,
}) => {
  const { alternativeVariantId, allowSubscriptions } = useShopifyData(
    `${extractNumericFromGID(productId, 'Product')}`,
    {
      sku: variantSku,
    },
    !!sellingPlanAllocation
  )

  const defaultPlan = getSellingPlan(alternativeVariantId, cart.sellingPlans)

  const removeItem = useCallback(() => {
    cart.removeItem(lineId)

    const products = [
      {
        sku: variantSku,
        name: title,
        id: variantId,
        price: linePrice,
        brand: 'OWYN',
        quantity: quantity,
        variant: variantTitle,
        image: image,
      },
    ]

    fireAnalyticsEvent({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: products,
        },
      },
    })

    // Elevar Analytics - Remove from Cart - dl_remove_from_cart
    var elevarProductArr = [
      {
        id: products[0].sku || '', // SKU
        name: products[0].name || '', // Product title
        brand: products[0].brand || '',
        category: '',
        variant: products[0].variant || '',
        price: products[0].price.toString() || '',
        quantity: products[0].quantity.toString() || '',
        list: '', // The list the product was discovered from
        product_id: products[0].id || '', // The product_id
        variant_id: products[0].id || '', // id or variant_id
        compare_at_price: '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
        image: products[0].image || '', // If available, otherwise use an empty string
        inventory: '',
      },
    ]

    const elevarPayloadCore = {
      remove: {
        actionField: {
          list: location.pathname, // this should be the collection page URL that user clicked product from
        },
        products: elevarProductArr, // See the products array below
      },
    }

    fireElevarAnalyticsEventGeneric('dl_remove_from_cart', elevarPayloadCore)
  }, [title, variantId, linePrice, quantity, variantTitle, cart, productId])

  const [showLearnMore, setShowLearnMore] = useState(false)
  const learnMoreRef = useRef()
  const textRef = useRef()
  useOnClickOutside([learnMoreRef, textRef], () => setShowLearnMore(false), showLearnMore)

  return (
    <div
      className='LineItem'
      css={{
        margin: '3rem 0',
      }}>
      <img
        src={image}
        alt={title}
        css={{
          width: '6.25rem',
          height: 'auto',
          background: theme.colors.w100,
        }}
      />

      <div className='ProductTitle'>
        <h3>
          {title}
          <br />
          {variantTitle}
        </h3>

        <div className='RemoveAction'>
          <InvisibleButton
            className='RemoveAction__button'
            css={{ ...theme.smallcaps }}
            onClick={removeItem}>
            <span className='RemoveAction__text'>Remove</span>
            <Icon.Close
              className='RemoveAction__icon'
              width={20}
              height={20}
              css={{ color: theme.colors.spinachDark }}
            />
          </InvisibleButton>
        </div>
      </div>

      <div
        className='PriceColumn'
        css={{
          ...theme.h5,
        }}>
        {sellingPlanAllocation && (
          <div css={{ ...theme.smallcaps, marginBottom: '.5rem' }}>
            <div>
              Discounted
              <br />
              Price
            </div>
          </div>
        )}

        <div css={{ color: theme.colors.spinach }}>${price}</div>
      </div>

      <div
        className='QuantityWrapper'
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          marginRight: '1rem',
        }}>
        <div
          className='TotalColumn'
          css={{
            ...theme.h5,
            color: theme.colors.spinach,
          }}>
          ${linePrice}
        </div>

        <QuantitySelector
          value={quantity}
          dispatch={({ change }) =>
            cart.updateItem({
              id: lineId,
              merchandiseId: variantId,
              quantity: change.quantity,
              attributes,
            })
          }
          className='QuantitySelector'
          css={{
            maxWidth: '6rem',
          }}
        />
      </div>

      {allowSubscriptions && (
        <div
          className='SubscribeButton'
          css={{
            [theme.mediumDown]: {
              display: 'none',
            },
          }}>
          <PurchaseTypeOption
            css={{
              width: 'fit-content',
              backgroundColor: '#D1EBE4',
              borderRadius: '4px',
              marginBottom: '1rem',
              cursor: 'initial',
              position: 'relative',
            }}
            onClick={() => {
              cart.updateItem({
                id: lineId,
                merchandiseId: `gid://shopify/ProductVariant/${alternativeVariantId}`,
                quantity,
                sellingPlanId: sellingPlanAllocation ? null : getSellingPlanId(defaultPlan),
              })
            }}
            active={!!sellingPlanAllocation}
            setOnClickToRadio>
            <>
              <span css={{ textAlign: 'left' }}>
                Subscribe and Save 20%
                <span
                  ref={textRef}
                  css={{
                    display: 'block',
                    fontSize: '.75rem',
                    color: theme.colors.spinach,
                    textDecoration: 'underline',
                    marginTop: '.25rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowLearnMore(true)}>
                  Learn More
                </span>
              </span>
              {showLearnMore && (
                <div ref={learnMoreRef}>
                  <SubscribeLearnMore onClose={() => setShowLearnMore(false)} />
                </div>
              )}
            </>
          </PurchaseTypeOption>
        </div>
      )}

      {sellingPlanAllocation && (
        <div
          className='SubscriptionDetails'
          css={{
            clear: 'both',
          }}>
          <SubscriptionInfo interval={sellingPlanAllocation.sellingPlan?.options[0]?.value} />
          <DiscountInfo>20% off all subscriptions</DiscountInfo>
        </div>
      )}
    </div>
  )
}

export default LineItem

LineItem.propTypes = {
  cart: PropTypes.shape({
    removeItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
  }).isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  sellingPlanAllocation: PropTypes.shape({
    sellingPlan: PropTypes.object,
    priceAdjustments: PropTypes.array,
  }),
  lineId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  linePrice: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  variantTitle: PropTypes.string.isRequired,
  alternativeVariantId: PropTypes.string,
}

const SubscriptionInfo = ({ interval }) => (
  <div
    css={{
      ...theme.paragraph,
      fontSize: '.875rem',
      marginBottom: '.5rem',
      display: 'flex',
    }}>
    <Icon.Truck width={20} height={20} css={{ color: theme.colors.spinachDark, marginRight: 14 }} />
    Ships every {interval}
  </div>
)

SubscriptionInfo.propTypes = {
  interval: PropTypes.string.isRequired,
}

const DiscountInfo = ({ children }) => (
  <div
    css={{
      ...theme.paragraph,
      fontSize: '.875rem',
      display: 'flex',
      alignItems: 'flex-start',
    }}>
    <Icon.SaleCallout
      width={20}
      height={20}
      css={{ color: theme.colors.spinachDark, marginRight: 14 }}
    />
    <div>{children}</div>
  </div>
)

DiscountInfo.propTypes = {
  children: PropTypes.node.isRequired,
}
